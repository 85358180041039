#top-bar {
    color: #b2b2b2;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);

    @media all and (max-width: $break-medium) {
        display: none;
    }

    .inner {
        padding: 10px 30px;

        a {
            color: #b2b2b2;

            &:hover {
                color: $orange;
            }
        }

        .contact {
            padding: 5px 0 0;
            float: left;
            font: 600 11px/100% $font_open;
            letter-spacing: 1px;

            li {
                margin-left: 30px;
                display: inline-block;

                @media all and (max-width: $break-large) {
                    margin-left: 15px;
                }

                &:first-of-type {
                    margin-left: 0;
                }

                i {
                    margin-right: 5px;
                    display: inline-block;
                    vertical-align: middle;
                    font-size: 15px;

                    &.laurel {
                        width: 19px;
                        height: 14px;
                        background: url(../img/icon_laurel.png) no-repeat 0 0;
                    }
                }
            }
        }

        #search {
            margin-right: 30px;
            float: right;
            position: relative;

            input {
                width: 260px;
                padding: 5px 0 5px 20px;
                border-width: 0 0 1px;
                border-style: solid;
                border-color: rgba(0, 0, 0, 0.1);
                font: 11px/100% $font_open;

                @media all and (max-width: $break-large) {
                    width: 180px;
                }

                @include placeholder {
                    color: $light_grey;
                };
            }

            i {
                position: absolute;
                top: 6px;
                left: 0;
                color: rgba(0, 0, 0, 0.3);
                font-size: 13px;
                @include transform(rotate(90deg));
            }
        }

        .social {
            float: right;

            li {
                margin-left: 20px;
                display: inline-block;
                vertical-align: middle;

                &:first-of-type {
                    margin-left: 0;
                }

                a {
                    &:hover {
                        color: $orange;
                    }
                }
            }
        }
    }
}
