#photo-boxes {
    .list {
        > a {
            min-height: 387px;
            display: block;
            background: url(../img/tmp/photo_5.jpg) no-repeat 0 0;
            position: relative;
            @include background-size(cover);

            @media all and (max-width: $break-medium) {
                min-height: 230px;
            }

            .details {
                width: 100%;
                padding: 0 60px;
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                text-align: center;
                color: $white;
                @include transform(translateY(-50%));

                h3 {
                    margin: 0;
                    font-size: 25px;

                    @media all and (max-width: $break-large) {
                        font-size: 22px;
                    }

                    @media all and (max-width: $break-medium) {
                        font-size: 18px;
                    }
                }

                .btn {
                    margin-top: 30px;
                    display: none;
                    background: transparent;
                    color: $white;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.6);
            }

            &:hover {
                .btn {
                    display: inline-block;
                    border-color: $white;

                    @media all and (max-width: $break-medium) {
                        display: none;
                    }
                }

                .overlay {
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f39924+0,f37124+100 */
                    background: rgb(243,153,36); /* Old browsers */
                    background: -moz-linear-gradient(top, rgba(243,153,36,1) 0%, rgba(243,113,36,1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(243,153,36,1) 0%,rgba(243,113,36,1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(243,153,36,1) 0%,rgba(243,113,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f39924', endColorstr='#f37124',GradientType=0 ); /* IE6-9 */
                    opacity: 0.9;
                }
            }
        }
    }
}
