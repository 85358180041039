#reviews-slider {
    position: relative;

    .bx-wrapper {
        margin: 0 auto;

        article {
            @include transition(0.5s opacity);

            &[aria-hidden="false"] {
                opacity: 0.5;

                @media all and (max-width: 1230px) {
                    opacity: 1;
                }
            }

            &[aria-hidden="true"] {
                opacity: 0.5 !important;

                @media all and (max-width: 1230px) {
                    opacity: 1 !important;
                }
            }

            &.active {
                opacity: 1 !important;
            }
        }
    }

    .bx-controls {
        .bx-prev,
        .bx-next {
            width: 70px;
            height: 70px;
            display: block;
            line-height: 70px;
            text-align: center;
            position: absolute;
            top: 50%;
            background: $orange;
            color: $white;
            font-size: 28px;
            @include transform(translateY(-50%));

            @media all and (max-width: $break-medium) {
                width: 35px;
                height: 35px;
                line-height: 33px;
                font-size: 22px;
                opacity: 1;
                @include transform(none);
            }
        }

        .bx-prev {
            left: 0;
            @include border-radius-separate(0, 5px, 0, 5px);
        }

        .bx-next {
            right: 0;
            @include border-radius-separate(5px, 0, 5px, 0);
        }
    }
}
