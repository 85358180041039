#breadcrumbs {
    padding: 10px 0;
    background: #F5F5F5;
    border-width: 1px 0;
    border-style: solid;
    border-color: #E6E6E6;
    overflow: hidden;

    .container-fluid {
        > ul {
            float: left;
            overflow: hidden;

            li {
                float: left;
                font: 11px/100% $font_open;
                letter-spacing: 1px;
                vertical-align: middle;

                a {
                    color: $black;
                    opacity: 0.4;
                    display: inline-block;
                    vertical-align: middle;
                    line-height: 17px;

                    &:hover {
                        opacity: 1;
                    }
                }

                &.home {
                    a {
                        width: 14px;
                        height: 17px;
                        background: url(../img/icon_home.png) no-repeat 0 0;
                    }
                }

                &:after {
                    margin: 0 10px;
                    font-family: FontAwesome;
                    font-size: 11px;
                    color: $orange;
                    content: '\f105';
                    vertical-align: -2px;
                }

                &:last-of-type {
                    &:after {
                        display: none;
                    }
                }
            }
        }

        .right {
            float: right;
            overflow: hidden;

            @media all and (max-width: $break-medium) {
                margin: 0 auto;
                float: none;
                margin-top: 15px;
                display: none;
            }

            a {
                color: #b2b2b2;

                &:hover {
                    color: $orange;
                }
            }

            #search {
                margin-right: 30px;
                float: right;
                position: relative;

                input {
                    width: 260px;
                    padding: 5px 5px 5px 30px;
                    border-width: 0 0 1px;
                    border-style: solid;
                    border-color: rgba(0, 0, 0, 0.1);
                    font: 11px/100% $font_open;

                    @media all and (max-width: $break-large) {
                        width: 180px;
                    }

                    @include placeholder {
                        color: $light_grey;
                    };
                }

                i {
                    position: absolute;
                    top: 6px;
                    left: 10px;
                    color: rgba(0, 0, 0, 0.3);
                    font-size: 13px;
                    @include transform(rotate(90deg));
                }
            }

            .social {
                float: right;

                li {
                    margin-left: 15px;
                    display: inline-block;
                    vertical-align: middle;

                    &:first-of-type {
                        margin-left: 0;
                    }

                    a {
                        &:hover {
                            color: $orange;
                        }
                    }
                }
            }
        }
    }
}
