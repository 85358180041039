#realization {
    padding: 0 0 32px;

    header {
        padding: 55px 0;
    }

    header + h3 {
        margin-bottom: 10px;
        border-top: 1px solid #E5E5E5;
        position: relative;

        span {
            padding-right: 35px;
            font-size: 25px;
            letter-spacing: -1px;
            background: $white;
            position: relative;
            top: -18px;
        }
    }

    .details {
        font: 600 16px/30px $font_open;

        ul {
            li {
                margin-top: 10px;

                &:first-of-type {
                    margin-top: 0;
                }

                span {
                    width: 110px;
                    display: inline-block;
                    font: 14px/24px $font_poppins;
                    opacity: 0.5;
                }
            }
        }
    }

    .text {
        font-size: 13px;
        line-height: 21px;
        color: #676767;

        @media all and (max-width: $break-medium) {
            margin-top: 25px;

            &:first-of-type {
                margin-top: 0;
            }
        }

        h3 {
            margin-bottom: 25px;
            font-size: 18px;
            color: #7F7F7F;

            @media all and (max-width: $break-medium) {
                margin-bottom: 15px;
            }
        }
    }

    #photo-slider {
        margin-top: 30px;
    }
}
