header.main {
    .inner {
        padding: 15px 30px;
        position: relative;

        @media all and (max-width: $break-medium) {
            padding-left: 15px;
            padding-right: 15px;
        }

        h1 {
            margin: 0;
            float: left;
            line-height: 0;
            font-size: 1px;

            img {
                width: 100%;
                max-width: 170px;

                @media all and (max-width: $break-large) {
                    max-width: 130px;
                }
            }
        }

        nav {
            float: right;

            @media all and (max-width: 940px) {
                width: 100%;
                float: none;
            }

            ul.main {
                padding-top: 14px;

                @media all and (max-width: $break-large) {
                    padding-top: 8px;
                }

                @media all and (max-width: 940px) {
                    width: 100%;
                    margin-top: 40px;
                    display: none;
                    text-align: center;
                }

                @media all and (min-width: 940px) {
                    display: block !important;
                }

                li {
                    margin-left: 45px;
                    float: left;

                    @media all and (max-width: $break-large) {
                        margin-left: 25px;
                    }

                    @media all and (max-width: 940px) {
                        margin: 0;
                        float: none;
                        border-top: 1px solid #e0e0e0;

                        &:first-of-type {
                            border-top: 0;
                        }
                    }

                    a {
                        padding: 4px 0;
                        font: 600 14px/100% $font_poppins;
                        color: $grey;
                        border-bottom: 3px solid transparent;

                        @media all and (max-width: 940px) {
                            padding: 15px;
                            display: block;
                            border: 0;
                        }

                        &:hover {
                            color: $black;
                            border-color: $orange;
                        }
                    }
                }
            }
        }

        ul.mobile {
            width: auto;
            margin: 0;
            padding: 0 !important;
            display: none;
            position: absolute;
            top: 20px;
            right: 10px;
            padding: 15px;

            @media all and (max-width: 940px) {
                display: inline-block;
            }

            li {
                margin-left: 10px;
                float: left;
                border: 0 !important;

                &:first-of-type {
                    margin-left: 0;
                }

                a {
                    padding: 10px;
                    color: $black;

                    i {
                        font-size: 28px;
                    }
                }
            }
        }
    }
}

#scroll-wrapper {
    position: relative;
    z-index: 999;

    &.fixed {
        > .inner {
            width: 100%;
            position: fixed;
            top: 0;
            left: 0;
            z-index: 9000;
            background: $white;
            border-bottom: 1px solid #e0e0e0;
        }
    }
}
