#sitemap {
    padding: 50px 15px;
    list-style-type: disc;

    li {
        a {
            color: $black;
            text-decoration: underline;

            &:hover {
                text-decoration: none;
                color: $orange;
            }
        }
    }

    ul {
        margin-left: 30px;
        list-style-type: disc;
    }
}
