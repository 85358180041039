#employees {
    padding: 58px 0 90px;

    @media all and (max-width: $break-large) {
        padding: 40px 0 70px;
    }

    @media all and (max-width: $break-medium) {
        padding: 30px 15px;
    }

    header {
        margin-bottom: 62px;

        @media all and (max-width: $break-medium) {
            margin-bottom: 30px;
        }
    }

    .list {
        > div {
            @media all and (max-width: $break-large) {
                margin-bottom: 10px;
            }

            @media all and (max-width: 575px) {
                margin-top: 15px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .inner {
                height: 100%;
                padding: 22px 22px 130px;
                text-align: center;
                position: relative;
                border: 1px solid #E4E4E4;

                h3 {
                    font-size: 22px;

                    @media all and (max-width: $break-large) {
                        font-size: 18px;
                    }
                }

                h4 {
                    margin-bottom: 30px;
                    font-weight: 300;
                    font-size: 14px;

                    @media all and (max-width: $break-medium) {
                        font-size: 12px;
                    }
                }

                .bottom {
                    width: 100%;
                    position: absolute;
                    left: 0;
                    bottom: 22px;
                }

                .photo-wrapper {
                    width: 80px;
                    height: 80px;
                    margin: 0 auto;
                    text-align: center;
                    display: block;
                    position: relative;

                    .photo {
                        width: 80px;
                        height: 80px;
                        margin-bottom: 30px;
                        padding: 5px;
                        background: $white;
                        border: 1px solid $orange;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 5;
                        @include border-radius(40px);
                    }

                    .photo-hover {
                        opacity: 0;
                        position: absolute;
                        top: 0;
                        left: 0;
                        z-index: 10;
                        @include transition(opacity 0.5s);
                    }
                }

                .contact {
                    li {
                        margin-left: 20px;
                        font-size: 19px;
                        display: inline-block;

                        &:first-of-type {
                            margin-left: 0;
                        }

                        a {
                            color: $black;

                            &:hover {
                                color: $orange;
                            }
                        }
                    }
                }

                &:hover {
                    border-color: $orange;

                    .photo {
                        border-width: 2px;
                    }

                    .photo-hover {
                        opacity: 1;
                    }
                }
            }
        }
    }
}
