#stats {
    padding: 75px 0;
    background: url(../img/bg_2.jpg) no-repeat 50% 50%;
    @include background-size(cover);

    @media all and (max-width: $break-medium) {
        padding: 30px 0;
    }

    .row {
        > div {
            @media all and (max-width: $break-medium) {
                margin-top: 25px;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            .inner {
                text-align: center;
                font-size: 19px;
                letter-spacing: -1px;

                @media all and (max-width: $break-large) {
                    font-size: 17px;
                }

                .circle {
                    width: 176px;
                    height: 176px;
                    margin: 0 auto 30px;
                    position: relative;
                    border: 2px solid $orange;
                    text-align: center;
                    line-height: 176px;
                    font-size: 36px;
                    letter-spacing: 3px;
                    @include border-radius(88px);

                    @media all and (max-width: $break-medium) {
                        width: 130px;
                        height: 130px;
                        margin-bottom: 15px;
                        @include border-radius(65px);
                        font-size: 24px;
                        line-height: 130px;
                    }

                    &:after {
                        width: 160px;
                        height: 160px;
                        display: block;
                        content: ' ';
                        position: absolute;
                        top: 6px;
                        left: 6px;
                        border: 1px solid $white;
                        @include border-radius(80px);

                        @media all and (max-width: $break-medium) {
                            width: 120px;
                            height: 120px;
                            top: 3px;
                            left: 3px;
                            @include border-radius(62px);
                        }
                    }
                }

                p {
                    margin-bottom: 0;
                }
            }
        }
    }
}
