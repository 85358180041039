header {
    &.with-line {
        h2 {
            margin-bottom: 18px;
            padding-top: 30px;
            position: relative;
            text-align: center;
            font: 42px/100% $font_poppins;
            letter-spacing: -1px;

            @media all and (max-width: $break-large) {
                font-size: 32px;
            }

            @media all and (max-width: $break-medium) {
                padding-top: 20px;
            }

            &:before {
                width: 36px;
                height: 2px;
                display: block;
                content: ' ';
                position: absolute;
                top: 0;
                left: 50%;
                background: $orange;
                @include transform(translateX(-50%));
            }
        }

        p {
            font: 15px/24px $font_poppins;
            color: $grey;

            @media all and (max-width: $break-medium) {
                margin-bottom: 0;
            }
        }
    }

    &.with-bg {
        margin: 0 -15px 30px;
        min-height: 280px;
        background-color: #bbb;
        background-repeat: no-repeat;
        background-position: 50% 50%;
        text-align: center;
        position: relative;
        @include background-size(cover);

        @media all and (max-width: $break-medium) {
            min-height: auto;
            padding: 60px 0 !important;
        }

        h2 {
            color: #fff;
            position: absolute;
            top: 50%;
            left: 50%;
            @include transform(translate(-50%, -50%));

            @media all and (max-width: $break-medium) {
                width: 100%;
                padding-left: 15px;
                padding-right: 15px;
            }
        }
    }
}

ul {
    &.arrows {
        li {
            margin-top: 20px;
            padding-left: 25px;
            position: relative;

            &:first-of-type {
                margin-left: 0;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                color: $orange;
                font-family: FontAwesome;
                font-size: 17px;
                content: '\f054';
            }
        }
    }

    &.numbers {
        li {
            margin-top: 20px;
            padding-left: 30px;
            position: relative;

            &:first-of-type {
                margin-left: 0;
            }

            .number {
                width: 30px;
                font-size: 21px;
                font-weight: bold;
                color: $orange;
                position: absolute;
                top: 0;
                left: 0;
            }
        }
    }
}

.wysiwyg {
    ul {
        li {
            margin-top: 20px;
            padding-left: 25px;
            position: relative;

            &:first-of-type {
                margin-left: 0;
            }

            &:before {
                position: absolute;
                top: 0;
                left: 0;
                color: $orange;
                font-family: FontAwesome;
                font-size: 17px;
                content: '\f054';
            }
        }
    }
}

.alert {
    margin: 10px 0;
    text-align: center;
    font-size: 14px;
    @include border-radius(0);
}

.readmore,
.readmore-link[data-readmore],
.readmore-link-short[data-readmore] {
    overflow: hidden;
    position: relative;

    &:after {
        width: 100%;
        height: 100px;
        position: absolute;
        left: 0;
        bottom: 0;
        content: ' ';
        display: block;
        background: url(../img/readmore_bg.png) repeat-x 0 0;
    }

    &.expanded:after {
        display: none !important;
    }
}

#ue-banner {
    padding: 10px;
    text-align: center;

    img {
        max-width: 100%;
    }
}
