.btn {
    height: 38px;
    padding: 0 30px;
    line-height: 35px;
    font-size: 13px;
    font-weight: bold;

    &.btn-light {
        background: transparent;
        border-color: $black;

        &:hover {
            border-color: $orange;
            color: $orange;
        }
    }

    &.btn-lg {
        padding: 0 40px;
        height: 50px;
        line-height: 47px;
        font-size: 17px;
    }

    &.btn-sm {
        padding: 0 10px;
        height: 25px;
        line-height: 25px;
        font-size: 11px;
    }

    i {
        margin-right: 8px;
        vertical-align: middle;
    }
}

/* Vertical carousel */

// .vert .carousel-item-next.carousel-item-left,
// .vert .carousel-item-prev.carousel-item-right {
//     -webkit-transform: translate3d(0, 0, 0);
//             transform: translate3d(0, 0, 0);
// }
//
// .vert .carousel-item-next,
// .vert .active.carousel-item-right {
//     -webkit-transform: translate3d(0, 100%, 0);
//             transform: translate3d(0, 100% 0);
// }
//
// .vert .carousel-item-prev,
// .vert .active.carousel-item-left {
// -webkit-transform: translate3d(0,-100%, 0);
//         transform: translate3d(0,-100%, 0);
// }
