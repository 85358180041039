body {
    color: $black;
    font-family: $font_poppins;
    background: $white;

    * {
        @include box-sizing(border-box);
    }

    a {
        &:hover {
            text-decoration: none;
        }
    }

    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
    }
}
