#about-us {
    header {
        padding: 55px 0;
        border-bottom: 1px solid #E2E4E3;

        @media all and (max-width: $break-large) {
            padding: 40px 0;
        }

        @media all and (max-width: $break-medium) {
            padding: 25px 0;
        }
    }

    .carousel {
        .carousel-item {
            min-height: 300px;

            .photo {
                height: 598px;
                background: url(../img/photo_1.jpg) no-repeat 50% 50%;
                @include background-size(cover);

                @media all and (max-width: $break-medium) {
                    height: 350px;
                }
            }

            .content {
                padding: 60px 250px 60px 130px;
                position: relative;

                @media all and (max-width: $break-large) {
                    padding: 40px 200px 40px 80px;
                }

                @media all and (max-width: $break-medium) {
                    padding: 30px 30px;
                }
            }

            h2 {
                margin-bottom: 25px;
                font-size: 25px;
                letter-spacing: -1px;
            }

            p {
                font-size: 13px;
                line-height: 21px;
                opacity: 0.6;
            }

            .contact {
                margin-top: 20px;
                padding-top: 20px;
                border-top: 1px solid #E9ECEB;

                p {
                    margin: 0;
                    font: 11px/100% $font_open;
                    letter-spacing: 1px;
                    float: left;
                    opacity: 1;
                    color: #9FA0A0;

                    i {
                        margin-right: 6px;
                        display: inline-block;
                        vertical-align: middle;
                        font-size: 22px;
                        color: #C8CAC9;
                    }
                }

                ul {
                    float: right;

                    li {
                        float: left;
                        margin-left: 20px;

                        &:first-of-type {
                            margin-left: 0;
                        }

                        a {
                            color: $black;
                            font-size: 22px;

                            i {
                                display: inline-block;
                                vertical-align: middle;
                                color: #C8CAC9;
                            }

                            &:hover i {
                                color: #989A9A;
                            }
                        }
                    }
                }
            }
        }
    }

    .controls {
        width: 30px;
        min-height: 190px;
        display: block;
        color: $black;
        text-align: center;
        position: absolute;
        top: 50%;
        right: 30px;
        background: $white;
        @include border-radius(5px);
        @include transform(translateY(-50%));

        @media all and (max-width: $break-medium) {
            right: 10px;
            display: none;
        }

        a {
            color: $black;
            opacity: 0.2;

            &:hover {
                opacity: 1;
            }
        }

        .carousel-control-prev,
        .carousel-control-next {
            width: auto;
            height: 30px;
            position: absolute;
            left: 0;
            display: inline-block;
            line-height: 100%;
            font-size: 40px;
        }

        .carousel-control-prev {
            top: 0;
        }

        .carousel-control-next {
            top: 150px;
        }

        .carousel-indicators {
            margin: 0 0 0 5px;
            padding: 0;
            display: block !important;
            position: absolute;
            top: 50%;
            left: 3px;
            @include transform(translateY(-50%));

            li {
                width: 6px;
                height: 6px;
                display: block !important;
                margin-top: 15px;
                background: $black;
                opacity: 0.2;
                @include border-radius(3px);

                &:first-of-type {
                    margin-top: 0;
                }

                &.active {
                    background: $orange;
                    opacity: 1;
                }
            }
        }
    }

    .bottom-content {
        .container {
            padding-top: 50px;
            padding-bottom: 50px;

            @media all and (max-width: $break-medium) {
                padding-top: 25px;
                padding-bottom: 25px;
            }

            p:last-of-type {
                margin-bottom: 0;
            }
        }
    }
}
