#realizations {
    padding: 58px 0 0;

    @media all and (max-width: $break-medium) {
        padding: 30px 15px;
    }

    .container-fluid {
        // overflow: hidden;
    }

    header {
        padding-bottom: 58px;
        text-align: center;

        @media all and (max-width: $break-medium) {
            padding-bottom: 20px;
        }

        h2 {
            display: inline-block;
        }

        &.with-casestudies h2:after {
            width: 208px;
            height: 199px;
            display: block;
            content: ' ';
            background: url(../img/casestudies.png) no-repeat 0 0;
            position: absolute;
            right: -320px;
            bottom: -140px;
            z-index: 5;

            @media all and (max-width: $break-medium) {
                display: none;
            }
        }

        &.with-casestudies.casestudies-right h2:after {
            right: -550px;
            bottom: -200px;
        }
    }

    .nav {
        margin: -25px 0 40px;
        display: block;
        text-align: center;

        @media all and (max-width: $break-medium) {
            margin: 5px 0 20px;
        }

        li {
            margin-left: 20px;
            display: inline-block;
            font-size: 14px;

            &:first-of-type {
                margin-left: 0;
            }

            @media all and (max-width: $break-medium) {
                margin: 5px 0 0;
                display: block;

                &:first-of-type {
                    margin-top: 0;
                }
            }

            a {
                padding: 10px 15px;
                color: $black;
                border: 1px solid #ccc;
                @include border-radius(5px);

                @media all and (max-width: $break-medium) {
                    width: 100%;
                    max-width: 250px;
                    padding: 3px 15px;
                    display: inline-block;
                }

                &:hover {
                    border-color: $orange;
                    color: $orange;
                }
            }

            &.active a {
                background: $orange;
                border-color: $orange;
                color: $white;
            }
        }
    }

    .list {
        // margin-left: -30px;
        // margin-right: -30px;

        > a {
            min-height: 290px;
            display: block;
            background: url(../img/tmp/photo_5.jpg) no-repeat 50% 50%;
            position: relative;
            @include background-size(cover);

            @media all and (max-width: $break-medium) {
                padding: 15px;
                min-height: 150px;
            }

            .details {
                width: 100%;
                padding: 0 60px;
                position: absolute;
                top: 50%;
                left: 0;
                z-index: 2;
                text-align: center;
                font: 12px/15px $font_open;
                color: $white;
                @include transform(translateY(-50%));

                h3 {
                    margin-bottom: 10px;
                    font-size: 19px;
                }

                p {
                    margin-bottom: 0;
                    opacity: 0.7;
                }

                .btn {
                    margin-top: 15px;
                    visibility: hidden;
                    display: inline-block;
                    background: transparent;
                    color: $white;

                    &:hover {
                        opacity: 0.8;
                    }
                }
            }

            .overlay {
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba(0, 0, 0, 0.6);
            }

            &:hover {
                .btn {
                    visibility: visible;
                    border-color: $white;
                }

                .overlay {
                    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#f39924+0,f37124+100 */
                    background: rgb(243,153,36); /* Old browsers */
                    background: -moz-linear-gradient(top, rgba(243,153,36,1) 0%, rgba(243,113,36,1) 100%); /* FF3.6-15 */
                    background: -webkit-linear-gradient(top, rgba(243,153,36,1) 0%,rgba(243,113,36,1) 100%); /* Chrome10-25,Safari5.1-6 */
                    background: linear-gradient(to bottom, rgba(243,153,36,1) 0%,rgba(243,113,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f39924', endColorstr='#f37124',GradientType=0 ); /* IE6-9 */
                    opacity: 0.9;
                }
            }

            &.hover-photo:hover {
                border-top: 3px solid $orange;

                .details,
                .overlay {
                    display: none;
                }
            }
        }
    }

    .show-more {
        padding: 48px 0 72px;
        text-align: center;
        position: relative;

        @media all and (max-width: $break-medium) {
            padding: 25px 0 0;
        }

        .suppliers {
            width: 208px;
            height: 199px;
            display: block;
            position: absolute;
            bottom: -50px;
            left: 5%;
            z-index: 10;
            background: url(../img/suppliers.png) no-repeat 0 0;

            @media all and (max-width: $break-medium) {
                display: none;
            }
        }
    }
}
