#search {
    .container {
        padding-bottom: 60px;

        header {
            padding: 55px 0;
        }

        h3 {
            margin: 35px 0 10px;
        }

        ul {
            padding-left: 25px;
            list-style-type: circle;

            li {
                a {
                    color: $orange;

                    &:hover {
                        text-decoration: underline;
                    }
                }
            }
        }

        .alert {
            text-align: left;
        }
    }
}
