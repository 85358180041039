#manufacturers {
    background: #eee;
    position: relative;

    .bx-wrapper {
        margin: 0 auto;
        padding: 50px;

        @media all and (max-width: 1366px) {
            padding: 30px;
        }

        @media all and (min-width: 1366px) and (max-width: 1920px) {
            max-width: 1230px !important;
        }

        @media all and (min-width: 1920px) {
            max-width: 1850px !important;
        }
    }

    .slider {
        > div {
            width: 140px;
            height: 140px;
            padding: 18px;
            background: $white;
            text-align: center;
            position: relative;

            img {
                max-width: 82%;
                opacity: 0.5;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                &:hover {
                    opacity: 1;
                }
            }
        }
    }

    .bx-controls {
        .bx-prev,
        .bx-next {
            position: absolute;
            top: 50%;
            font-size: 30px;
            color: $black;
            @include transform(translateY(-50%));
        }

        .bx-prev {
            left: 30px;

            @media all and (max-width: 1366px) {
                left: 10px;
            }
        }

        .bx-next {
            right: 30px;

            @media all and (max-width: 1366px) {
                right: 10px;
            }
        }
    }
}
