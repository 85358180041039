footer.main {
    background: $black;
    border-top: 1px solid #333;

    .top {
        margin: 0 30px;
        padding: 25px 0;
        border-width: 1px 0;
        border-style: solid;
        border-color: #333;

        @media all and (max-width: $break-medium) {
            text-align: center;
        }

        .logo {
            float: left;

            @media all and (max-width: $break-medium) {
                margin-bottom: 15px;
                display: block;
                float: none;
            }
        }

        .footer-nav {
            padding: 5px 15px 0 0;
            float: right;

            @media all and (max-width: $break-medium) {
                float: none;
                text-align: center;
            }

            li {
                margin-left: 30px;
                float: left;

                @media all and (max-width: $break-medium) {
                    margin: 5px 0 0;
                    display: block;
                    float: none;
                }

                &:first-of-type {
                    margin-left: 0;

                    @media all and (max-width: $break-medium) {
                        margin-top: 0;
                    }
                }

                a {
                    color: $white;
                    text-transform: uppercase;
                    font-size: 10px;
                    letter-spacing: 1px;
                    opacity: 0.7;

                    &:hover {
                        opacity: 1;
                    }
                }
            }
        }
    }

    .bottom {
        .inner {
            margin: 0 30px;
            padding: 15px 0;

            .copyright {
                padding-top: 5px;
                font: 10px/100% $font_open;
                opacity: 0.4;
                color: $white;
                text-align: center;
            }

            .social {
                float: right;
                overflow: hidden;

                @media all and (max-width: $break-medium) {
                    margin-top: 10px;
                    float: none;
                    text-align: center;
                }

                li {
                    float: left;
                    margin-left: 25px;

                    @media all and (max-width: $break-medium) {
                        display: inline-block;
                        float: none;
                    }

                    &:first-of-type {
                        margin-left: 0;
                    }

                    a {
                        color: $white;
                        opacity: 0.35;

                        &:hover {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
