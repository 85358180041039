#product {
    .container {
        padding-bottom: 60px;

        header {
            margin-bottom: 15px;
            padding: 30px 0 25px;
            border-bottom: 1px solid $orange;

            h2 {
                margin-bottom: 0;
                padding-top: 0;
                text-align: left;
                font-size: 34px;
                font-family: $font_poppins;

                &:before {
                    display: none;
                }
            }
        }

        .text {
            margin: 0 0 40px;
        }

        header + h3 {
            margin-bottom: 10px;
            border-top: 1px solid #E5E5E5;
            position: relative;

            span {
                padding-right: 35px;
                font-size: 25px;
                letter-spacing: -1px;
                background: $white;
                position: relative;
                top: -18px;
            }
        }

        .more-link {
            font-style: italic;
            font-size: 13px;
            color: $orange;
        }

        .row {
            .details {
                font: 400 14px/100% $font_poppins;

                .logo {
                    height: 32px;
                }

                ul:not(.numbers) {
                    li {
                        margin-top: 5px;

                        &:first-of-type {
                            margin-top: 0;
                        }

                        span {
                            width: 110px;
                            display: inline-block;
                            font: 14px/24px $font_poppins;
                            opacity: 0.5;
                        }

                        #rating,
                        .rating-text {
                            display: inline-block;
                        }

                        .rating-text {
                            width: auto;
                            margin-left: 5px;
                            color: #ccc;
                            opacity: 1;
                            font-size: 12px;

                            strong {
                                font-size: 18px;
                                font-weight: bold;
                                color: $orange;
                            }
                        }

                        .vehicletypes {
                            margin-top: -10px;
                            display: inline-block;

                            li {
                                margin-left: 10px;
                                display: inline-block;

                                &:first-of-type {
                                    margin-left: 0;
                                }

                                img {
                                    vertical-align: middle;
                                }
                            }
                        }
                    }
                }

                .short-description {
                    margin-top: 10px;
                    line-height: 21px;
                    font-size: 13px;
                    font-weight: normal;
                    color: #666;
                }

                .features {
                    ul {
                        li {
                            display: block;
                            font-size: 13px;
                            line-height: 21px;
                            color: #666;

                            // &:nth-child(-n+3) {
                            //     display: block;
                            // }
                        }
                    }

                    .buttons {
                        margin-top: 20px;
                        display: none;
                    }

                    &.show-all {
                        ul li {
                            display: block;
                        }
                    }

                    a {
                        color: $orange;
                    }
                }

                .actions {
                    margin: 10px 0;
                    padding-bottom: 15px;
                    border-bottom: 1px solid $orange;

                    li {
                        margin: 0 0 0 20px !important;
                        float: left;
                        position: relative;

                        &:first-of-type {
                            margin-left: 0 !important;
                        }

                        .btn {
                            height: auto;
                            padding: 2px 10px;
                            background: $orange;
                            border: 0;
                            font-weight: bold;
                            outline: none;
                            line-height: 1.5;
                            @include border-radius(0);

                            &:hover,
                            &:focus {
                                outline: none;
                            }
                        }

                        a:not(.btn) {
                            margin-top: 10px;
                            display: inline-block;
                            color: $orange;
                            font-size: 14px;
                            line-height: 100%;
                        }

                        ul {
                            min-width: 160px;
                            position: absolute;
                            z-index: 100;
                            top: 22px;
                            left: 0;
                            display: none;
                            background: $white;
                            border: 1px solid $orange;
                            line-height: 100%;

                            li {
                                margin: 0 !important;
                                padding: 0 !important;
                                float: none !important;

                                a {
                                    margin: 0 !important;
                                    color: #666 !important;
                                    padding: 5px 8px;
                                    display: block !important;

                                    &:hover {
                                        color: $orange !important;
                                    }
                                }

                                &.all {
                                    a {
                                        background: $orange !important;
                                        color: $white !important;
                                    }
                                }
                            }
                        }

                        &:hover {
                            ul {
                                display: block;
                            }
                        }
                    }
                }

                form.cart-add {
                    button[type="submit"] {
                        padding-top: 6px;
                        background: $white;
                        border: 0;
                        color: $orange;
                        font-size: 14px;
                        cursor: pointer;

                        &:hover {
                            color: #666;
                        }
                    }
                }
            }

            .text {
                font-size: 13px;
                line-height: 21px;
                color: #666;

                @media all and (max-width: $break-medium) {
                    margin-top: 35px;
                    font-size: 12px;
                    line-height: 18px;
                }

                h3 {
                    margin-bottom: 25px;
                    font-size: 18px;

                    @media all and (max-width: $break-medium) {
                        font-size: 17px;
                    }
                }

                .buttons {
                    margin-top: 50px;

                    @media all and (max-width: $break-medium) {
                        margin-top: 25px;
                    }

                    li {
                        margin-left: 10px;
                        float: left;

                        &:first-of-type {
                            margin-left: 0;
                        }

                        @media all and (max-width: $break-large) {
                            margin: 10px 0 0;
                            float: none;

                            &:first-of-type {
                                margin-top: 0;
                            }
                        }

                        .btn {
                            padding-left: 15px;
                            padding-right: 15px;
                        }
                    }
                }

                .more-link {
                    font-size: 13px;
                    font-style: italic;
                    color: $orange;
                }
            }
        }
    }

    .specification {
        padding-top: 30px;
        font-size: 13px;
        line-height: 100%;
        color: #666;

        h3 {
            margin-bottom: 20px;
            font-size: 18px;
            display: inline-block;
        }

        .variant-select {
            min-width: 160px;
            margin-left: 10px;
            min-height: 25px;
            vertical-align: middle;
            display: inline-block;
            position: relative;

            ul {
                min-width: 160px;
                position: absolute !important;
                top: -2px;
                left: 0;
                line-height: 100%;
                background: $white;
                border: 1px solid $orange;
                position: relative;

                li {
                    display: none;

                    &.active {
                        display: block;
                    }

                    a {
                        padding: 6px;
                        color: #666;
                        display: block;

                        &:hover {
                            color: $orange;
                        }
                    }
                }

                &:hover {
                    li {
                        display: block;
                    }
                }
            }

            i {
                position: absolute;
                top: 5px;
                right: 8px;
                color: $orange;
            }
        }

        table {
            width: 100%;

            tr {
                td {
                    padding: 5px 5px 5px 0;
                }
            }
        }

        .variant {
            display: none;

            &.active {
                display: block;
            }
        }
    }

    .variants {
        margin-top: 30px;

        h3 {
            margin: 0 0 10px !important;
            font-weight: bold;
            font-size: 13px !important;
            color: $orange;
        }

        ul {
            li {
                margin: 0 0 5px 3px;
                display: inline-block;

                a {
                    padding: 3px 6px;
                    display: inline-block;
                    border: 1px solid $orange;
                    font-size: 13px;
                    color: $orange;
                }

                &.active a,
                a:hover {
                    background: $orange;
                    color: $white;
                }
            }
        }
    }

    .photo-slider {
        margin-bottom: 60px;
        display: none;

        &.active {
            display: block;
        }
    }
}
