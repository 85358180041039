#categories {
    a {
        .photo {
            min-height: 380px;
            position: relative;
            overflow: hidden;

            @media all and (max-width: $break-large) {
                min-height: 300px;
            }

            @media all and (max-width: $break-medium) {
                min-height: 120px;
            }

            .bg {
                width: 100%;
                height: 100%;
                position: absolute;
                top: 0;
                left: 0;
                display: block;
                @include background-size(cover);
                background-position: 50% 50%;
                @include transition(all 0.5s);
            }
        }

        .text {
            min-height: 380px;
            border-top: 1px solid rgba(0, 0, 0, 0.1);
            position: relative;

            @media all and (max-width: $break-large) {
                min-height: 300px;
            }

            @media all and (max-width: $break-medium) {
                min-height: 0;
            }

            .inner {
                width: 100%;
                padding: 0 50px;
                position: absolute;
                top: 50%;
                left: 0;
                color: $black;
                @include transform(translateY(-50%));

                @media all and (max-width: $break-large) {
                    padding: 0 30px;
                }

                @media all and (max-width: $break-medium) {
                    padding: 25px 0;
                    position: static;
                    @include transform(none);
                }

                h2 {
                    margin-bottom: 20px;
                    position: relative;
                    font: 700 15px/100% $font_open;
                    text-transform: uppercase;

                    @media all and (max-width: 1600px) {
                        margin-bottom: 10px;
                        font-size: 13px;
                    }

                    div {
                        margin-top: 20px;
                        padding-left: 65px;
                        position: relative;
                        display: block;
                        font: bold 36px/100% $font_poppins;
                        letter-spacing: -1px;
                        color: $orange;
                        text-transform: none;

                        @media all and (max-width: 1600px) {
                            margin-top: 10px;
                            font-size: 22px;
                        }

                        .icon-wrapper {
                            width: 44px;
                            height: 44px;
                            margin-top: -5px;
                            position: absolute;
                            top: 0;
                            left: 0;
                            display: inline-block;

                            .icon {
                                position: absolute;
                                top: 0;
                                left: 0;
                                z-index: 5;
                                @include transition(opacity 0.5s);

                                &.icon-hover {
                                    z-index: 10;
                                    opacity: 0;
                                }
                            }
                        }
                    }
                }

                p {
                    width: 60%;
                    font-size: 13px;
                    line-height: 21px;
                    color: $grey;

                    @media all and (max-width: $break-large) {
                        width: 100%;
                        font-size: 11px;
                    }

                    @media all and (max-width: $break-medium) {
                        margin-bottom: 0;
                    }
                }

                .btn {
                    margin-top: 10px;
                    visibility: hidden;
                    background: transparent;
                }
            }
        }

        &:hover {
            background: #eda12a; /* Old browsers */
            background: -moz-linear-gradient(top, #eda12a 0%, #ec7d2f 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(top, #eda12a 0%,#ec7d2f 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to bottom, #eda12a 0%,#ec7d2f 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eda12a', endColorstr='#ec7d2f',GradientType=0 ); /* IE6-9 */

            .photo {
                .bg {
                    @include transform(scale(1.2));
                }
            }

            .inner {
                color: $white;

                h2,
                h2 div,
                p {
                    color: $white;
                }

                .btn {
                    display: inline-block;
                    color: $white;
                    visibility: visible;
                    border-color: $white;
                }

                h2 div .icon-wrapper .icon-hover {
                    opacity: 1 !important;
                }
            }
        }
    }
}
