#reviews {
    padding: 57px 0;
    background: #F2F2F2;

    .container {
        > header {
            margin-bottom: 48px;
        }
    }

    article {
        padding: 50px;
        background: #F9F9F9;
        @include border-radius(10px);

        @media all and (max-width: $break-large) {
            padding: 40px 50px;
        }

        @media all and (max-width: $break-medium) {
            padding: 30px 50px;
        }

        header {
            margin-bottom: 32px;
            padding-bottom: 32px;
            padding-left: 75px;
            position: relative;
            border-bottom: 1px solid #EDEDED;

            @media all and (max-width: $break-medium) {
                margin-bottom: 25px;
                padding-bottom: 25px;
                padding-left: 60px;
            }

            h4 {
                margin-bottom: 4px;
                font: 600 13px/100% $font_open;
                text-transform: uppercase;
                letter-spacing: 1px;
                color: #4A4A4A;
            }

            h3 {
                font-weight: 600;
                font-size: 22px;
                color: $orange;

                @media all and (max-width: $break-large) {
                    font-size: 18px;
                }
            }

            img {
                width: 45px;
                height: 45px;
                padding: 2px;
                background: $white;
                border: 2px solid $orange;
                position: absolute;
                top: 0;
                left: 0;
                @include border-radius(23px);
            }
        }

        p {
            font-size: 25px;
            line-height: 140%;

            @media all and (max-width: $break-large) {
                font-size: 21px;
            }

            @media all and (max-width: $break-medium) {
                font-size: 18px;
            }
        }

        footer {
            margin-top: 50px;
            font: 600 11px/100% $font_open;
            letter-spacing: 1px;
            text-transform: uppercase;
            color: #4A4A4A;

            @media all and (max-width: $break-medium) {
                margin-top: 30px;
            }

            i {
                margin-right: 10px;
                font-size: 18px;
                color: $orange;
                display: inline-block;
                vertical-align: -2px;
            }
        }
    }
}
