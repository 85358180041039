#photo-slider,
.photo-slider {
    position: relative;

    > .inner {
        max-width: 1170px;
        margin: 0 auto;
    }

    .owl-stage {
        .owl-item {
            position: relative;

            .img-wrapper {
                display: block;
                height: 299px;
                text-align: center;
                line-height: 299px;


                img {
                    width: auto !important;
                    max-width: 100%;
                    max-height: 100%;
                    display: inline-block;
                }
            }
        }
    }

    .owl-nav {
        .owl-prev,
        .owl-next {
            width: 70px;
            height: 70px;
            display: block;
            line-height: 70px;
            text-align: center;
            position: absolute;
            top: 50%;
            background: $orange;
            color: $white;
            font-size: 28px;
            opacity: 0.5;
            cursor: pointer;
            @include transform(translateY(-50%));

            @media all and (max-width: $break-medium) {
                width: 35px;
                height: 35px;
                line-height: 33px;
                font-size: 22px;
                opacity: 1;
                @include transform(none);
            }

            &:hover {
                opacity: 1;
            }
        }

        .owl-prev {
            left: 0;
            @include border-radius-separate(0, 5px, 0, 5px);
        }

        .owl-next {
            right: 0;
            @include border-radius-separate(5px, 0, 5px, 0);
        }
    }
}
