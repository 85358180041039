#products {
    .container-fluid {
        header {
            padding: 55px 0;

            @media all and (max-width: $break-large) {
                padding: 40px 0;
            }

            @media all and (max-width: $break-medium) {
                padding: 30px 15px;
            }
        }

        #filter {
            margin: 30px 0;

            .form-group {
                label {
                    font-weight: bold;
                }
            }
        }

        .list {
            border-bottom: 1px solid #E5E5E5;

            a {
                border-width: 1px 0 0 1px;
                border-style: solid;
                border-color: #E5E5E5;
                color: $black;

                .row {
                    .details {
                        min-height: 290px;
                        position: relative;

                        @media all and (max-width: 768px) {
                            padding: 25px 15px;
                            min-height: 0 !important;
                        }

                        .inner {
                            width: 100%;
                            max-width: 230px;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            text-align: center;
                            z-index: 2;
                            @include transform(translate(-50%, -50%));

                            h3 {
                                font-size: 17px;
                                letter-spacing: -1px;
                            }

                            p {
                                margin: 0;
                                font: 10px/13px $font_open;
                                opacity: 0.7;
                            }

                            .btn {
                                margin-top: 15px;
                                display: none;
                            }
                        }

                        .overlay {
                            width: 100%;
                            height: 100%;
                            display: block;
                            position: absolute;
                            top: 0;
                            left: 0;
                            z-index: 1;
                            display: none;
                            background: rgb(244,162,57); /* Old browsers */
                            background: -moz-linear-gradient(top, rgba(244,162,57,1) 0%, rgba(244,126,57,1) 99%); /* FF3.6-15 */
                            background: -webkit-linear-gradient(top, rgba(244,162,57,1) 0%,rgba(244,126,57,1) 99%); /* Chrome10-25,Safari5.1-6 */
                            background: linear-gradient(to bottom, rgba(244,162,57,1) 0%,rgba(244,126,57,1) 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f4a239', endColorstr='#f47e39',GradientType=0 ); /* IE6-9 */

                            &:after {
                                left: 100%;
                                top: 50%;
                                border: solid transparent;
                                content: " ";
                                height: 0;
                                width: 0;
                                position: absolute;
                                pointer-events: none;
                                border-color: rgba(255, 255, 255, 0);
                                border-left-color: #F49039;
                                border-width: 20px;
                                margin-top: -20px;
                            }
                        }
                    }

                    .photo {
                        position: relative;
                        padding-left: 0 !important;
                        padding-right: 0 !important;

                        @media all and (max-width: 768px) {
                            padding-bottom: 15px;
                            text-align: center;
                        }

                        img {
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            max-width: 90%;
                            max-height: 90%;
                            @include transform(translate(-50%, -50%));

                            @media all and (max-width: 768px) {
                                max-height: 130px;
                                position: static;
                                @include transform(none);
                            }
                        }
                    }
                }

                &:hover {
                    @media all and (min-width: 768px) {
                        color: $white;

                        .row .details .inner .btn {
                            display: inline-block;
                            color: $white;
                            border-color: $white;
                        }

                        .row .details .overlay {
                            display: block;
                        }
                    }
                }
            }
        }

        .show-more {
            padding: 48px 0;
            text-align: center;
            position: relative;
        }
    }

    &.with-info {
        .container-fluid {
            .list {
                border-bottom: 1px solid #E5E5E5;

                > a {
                    border-width: 1px 0 0 1px;
                    border-style: solid;
                    border-color: #E5E5E5;
                    position: relative;

                    &:before {
                        width: 100%;
                        height: 4px;
                        background: $orange;
                    }

                    .details {
                        min-height: 194px;

                        .inner {
                            h3 {
                                font-size: 13px;
                            }

                            i {
                                display: none;
                            }
                        }
                    }

                    &:hover {
                        .details i {
                            display: block;
                        }
                    }
                }
            }
        }
    }

    // iframe {
    //     width: 576px;
    //
    //     @media all and (max-width: $break-medium) {
    //         width: 100%;
    //     }
    // }
}
