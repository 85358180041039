@font-face {
    font-family: 'mechanic_handregular';
    src: url('../fonts/mechanichand-webfont.eot');
    src: url('../fonts/mechanichand-webfont.eot?#iefix') format('embedded-opentype'),
         url('../fonts/mechanichand-webfont.woff2') format('woff2'),
         url('../fonts/mechanichand-webfont.woff') format('woff'),
         url('../fonts/mechanichand-webfont.ttf') format('truetype'),
         url('../fonts/mechanichand-webfont.svg#mechanic_handregular') format('svg');
    font-weight: normal;
    font-style: normal;
}
