#what-we-do {
    padding: 58px 0;
    text-align: center;
    color: $white;
    background: url(../img/bg_1.jpg) no-repeat 50% 50%;
    @include background-size(cover);

    @media all and (max-width: $break-medium) {
        padding: 40px 0;
    }

    .inner {
        max-width: 942px;
        margin: 0 auto;

        h2 {
            margin-bottom: 40px;
            padding-top: 30px;
            position: relative;
            display: inline-block;
            font: 20px/140% $font_mechanic;

            &:before {
                width: 36px;
                height: 2px;
                display: block;
                content: ' ';
                position: absolute;
                top: 0;
                left: 50%;
                background: $orange;
                @include transform(translateX(-50%));
            }

            &:after {
                width: 51px;
                height: 89px;
                display: block;
                content: ' ';
                position: absolute;
                right: -50px;
                bottom: -70px;
                background: url(../img/arrow.png) no-repeat 0 0;
            }
        }

        .icons {
            > div {
                @media all and (max-width: $break-medium) {
                    margin-top: 50px;

                    &:first-of-type {
                        margin-top: 0;
                    }
                }
            }

            .icon {
                min-height: 198px;
                margin-bottom: 20px;

                @media all and (max-width: $break-medium) {
                    min-height: 0;
                }

                img {
                    @media all and (max-width: $break-medium) {
                        max-width: 130px;
                    }
                }
            }

            h3 {
                padding: 0 10px;
                font: 21px/100% $font_poppins;
                color: $white;
                display: inline-block;
                position: relative;
                z-index: 2;

                @media all and (max-width: $break-medium) {
                    font-size: 18px;
                }

                span {
                    width: 100%;
                    height: 8px;
                    background: $orange;
                    position: absolute;
                    bottom: 0;
                    left: 0;
                    z-index: -1;
                    display: none;
                }
            }

            .icons {
                > div:hover {
                    span {
                        display: block;
                    }
                }
            }
        }
    }
}
