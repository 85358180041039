// Colors
$white: #fff;
$black: #000;
$grey: #646665;
$light_grey: #888;
$orange: #F37124;


// Breakpoints
$break-extra-large: '1450px';
$break-large: '1200px';
$break-medium: '767px';
$break-small: '575px';

// Fonts
$font_poppins: 'Poppins', sans-serif;
$font_open: 'Open Sans', sans-serif;
$font_mechanic: 'mechanic_handregular', sans-serif;
