#cart {
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 15000;

    &.open {
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        bottom: auto;
        right: auto;
    }

    .open {
        width: 55px;
        height: 55px;
        display: block;
        background: $white url(../img/icon_list.png) no-repeat 50% 50%;
        background-size: 70%;
        @include border-radius(5px);
        @include box-shadow(0, 0, 10px, rgba(0, 0, 0, 0.2));

        .total-items {
            width: 22px;
            height: 22px;
            position: absolute;
            top: -12px;
            right: -12px;
            background: $orange;
            color: $white;
            text-align: center;
            line-height: 22px;
            font-size: 11px;
            @include border-radius(12px);
        }
    }

    > .items,
    > .email {
        width: 450px;
        display: none;
        background: $white;
        position: absolute;
        top: 50%;
        left: 50%;
        @include transform(translate(-50%, -50%));
        @include border-radius(5px);
        @include transition(0.5s all);

        @media all and (max-width: 768px) {
            width: 90%;
        }

        h3 {
            padding: 15px 20px;
            font-size: 13px;
            color: #7F7F7F;
            border-bottom: 1px solid #E5E5E5;
        }
    }

    > .items {
        ul {
            li {
                padding: 20px;
                overflow: hidden;
                border-top: 1px solid #E5E5E5;

                &:first-of-type {
                    border-top: 0;
                }

                > * {
                    float: left;
                }

                .image {
                    width: 25%;
                }

                .name {
                    width: 40%;
                    font-size: 13px;

                    a {
                        color: $black;

                        &:hover {
                            color: $orange;
                        }
                    }
                }

                .btn-wrapper {
                    width: 35%;

                    .btn {
                        font-size: 10px;
                        display: inline-block;
                        text-align: center;

                        &.remove {
                            margin-left: 4px;

                            i {
                                margin-right: 0;
                            }
                        }

                        @media all and (max-width: 768px) {
                            margin: 5px 0 0 !important;
                            display: block;

                            &:first-of-type {
                                margin-top: 0;
                            }
                        }
                    }
                }
            }
        }

        .empty {
            padding: 20px;
            font-size: 12px;
            line-height: 18px;
        }
    }

    > .email {
        form {
            fieldset {
                padding: 20px;

                .input-wrapper {
                    margin-top: 10px;
                    position: relative;

                    &:first-of-type {
                        margin-top: 0;
                    }

                    input[type="text"],
                    input[type="email"],
                    textarea {
                        width: 100%;
                        height: 46px;
                        padding: 0 15px;
                        border: 1px solid #eee;
                        font-size: 14px;
                        outline: none;

                        &:focus {
                            border-color: $orange;
                        }
                    }

                    textarea {
                        height: 100px;
                        padding-top: 10px;
                        padding-bottom: 10px;
                    }

                    &.checkbox {
                        padding-left: 25px;
                        position: relative;
                        font-size: 11px;

                        input {
                            position: absolute;
                            top: 2px;
                            left: 0;
                        }
                    }
                }
            }
        }
    }

    .bottom-buttons {
        overflow: hidden;
        @include box-shadow(0, -5px, 5px, rgba(0, 0, 0, 0.1));

        a,
        button {
            height: 54px;
            display: block;
            float: left;

            &.primary {
                background: #F39924;
                line-height: 54px;
                color: $white;
                font-weight: bold;
                font-size: 13px;
                text-align: center;
                border: 0;
                cursor: pointer;
                @include border-radius-separate(0, 0, 5px, 0);

                i {
                    marign-left: 10px;
                    display: inline-block;
                }
            }

            &.close,
            &.back {
                width: 54px;
                text-align: center;
                color: #000;
                line-height: 54px;
                opacity: 0.5;

                &:hover {
                    opacity: 1;
                }
            }

            @media all and (max-width: 768px) {
                &.primary {
                    width: 75%;
                }

                &.back,
                &.close {
                    width: 25%;
                }
            }

            &.back {
                border-right: 1px solid #ccc;
            }
        }
    }

    .error-container {
        display: none;
    }
}

#cart-overlay {
    width: 100%;
    height: 100%;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 14000;
    background: rgba(0, 0, 0, 0.2);
    @include transition(0.5s all);
}
