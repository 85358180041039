#contact {
    position: relative;

    #contact-map {
        width: 50%;
        float: left;
        height: 720px;

        @media all and (max-width: $break-medium) {
            width: 100%;
            float: none;
            height: 300px;
        }
    }

    > .details {
        width: 50%;
        float: right;
        position: relative;
        height: 720px;
        z-index: 900;
        color: $white;
        background: #000;

        @media all and (max-width: $break-medium) {
            width: 100%;
            height: auto;
            padding: 30px 15px;
            position: static;
        }

        > .inner {
            width: 100%;
            position: absolute;
            top: 50%;
            left: 0;
            padding: 0 115px;
            @include transform(translateY(-50%));

            @media all and (max-width: $break-large) {
                padding: 0 40px;
            }

            @media all and (max-width: $break-medium) {
                padding: 0;
                position: static;
                @include transform(none);
            }

            h2 {
                margin-bottom: 30px;
                padding-top: 25px;
                position: relative;
                font-size: 42px;

                @media all and (max-width: $break-large) {
                    font-size: 32px;
                }

                @media all and (max-width: $break-medium) {
                    padding-top: 15px;
                    font-size: 22px;
                }

                &:before {
                    width: 36px;
                    height: 2px;
                    display: block;
                    content: ' ';
                    position: absolute;
                    top: 0;
                    left: 0;
                    background: $orange;
                }
            }

            h2 + p {
                font: 300 18px/100% $font_poppins;
                line-height: 30px;
                opacity: 0.6;

                @media all and (max-width: $break-large) {
                    font-size: 16px;
                    line-height: 28px;
                }

                @media all and (max-width: $break-medium) {
                    font-size: 14px;
                    line-height: 22px;
                }
            }

            ul {
                margin-top: 50px;

                @media all and (max-width: $break-large) {
                    margin-top: 40px;
                }

                @media all and (max-width: $break-medium) {
                    margin-top: 25px;
                }

                li {
                    margin-top: 15px;
                    font-size: 16px;
                    position: relative;
                    padding-left: 108px;

                    @media all and (max-width: $break-medium) {
                        font-size: 14px;
                    }

                    &:first-of-type {
                        margin-top: 0;
                    }

                    > span {
                        min-width: 108px;
                        position: absolute;
                        top: 0;
                        left: 0;
                        display: inline-block;
                        font-size: 14px;
                        opacity: 0.6;

                        @media all and (max-width: $break-medium) {
                            display: block;
                            font-size: 12px;
                        }
                    }

                    a {
                        display: inline-block;
                        position: relative;
                        color: $white;

                        span {
                            width: 100%;
                            height: 8px;
                            background: $orange;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            z-index: -1;
                            display: none;
                        }

                        &:hover {
                            span {
                                display: block;
                            }
                        }
                    }
                }
            }

            .spinner {
                .img-wrapper {
                    display: none;
                    text-align: center;
                }

                &.active {
                    .img-wrapper {
                        display: block;
                    }

                    #contacts-carousel-wrapper {
                        display: none;
                    }
                }
            }
        }
    }

    form {
        width: 100%;

        label {
            margin: 25px 0;
            font-weight: 300;
            font-size: 18px;
            opacity: 0.6;

            &:first-of-type {
                margin-top: 0;
            }
        }

        .input-wrapper {
            overflow: hidden;
            position: relative;

            input {
                width: 100%;
                height: 47px;
                padding: 0 124px 0 20px;
                color: $white;
                border: 1px solid $orange;
                font: 14px/46px $font_poppins;
                background: transparent;
                outline: none;
                @include border-radius(5px);
            }

            select {
                width: 100%;
                height: 46px;
                padding: 0 20px;
                font: 14px/46px $font_poppins;
                background: transparent;
                border: 1px solid $orange;
                color: $white;
                @include border-radius(5px);

                option {
                    color: $black;
                }
            }

            button {
                width: 113px;
                height: 38px;
                display: block;
                position: absolute;
                top: 3px;
                right: 3px;
                line-height: 38px;
                text-align: center;
                color: $white;
                font-weight: bold;
                border: 0;
                cursor: pointer;
                background: rgb(243,153,36); /* Old browsers */
                background: -moz-linear-gradient(top, rgba(243,153,36,1) 0%, rgba(243,113,36,1) 100%); /* FF3.6-15 */
                background: -webkit-linear-gradient(top, rgba(243,153,36,1) 0%,rgba(243,113,36,1) 100%); /* Chrome10-25,Safari5.1-6 */
                background: linear-gradient(to bottom, rgba(243,153,36,1) 0%,rgba(243,113,36,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
                filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f39924', endColorstr='#f37124',GradientType=0 ); /* IE6-9 */
                @include border-radius(5px);
            }
        }
    }

    #contacts-carousel {
        margin-top: 25px;

        .carousel-inner {
            .carousel-item {
                .inner {
                    ul {
                        margin-top: 0;
                    }
                }
            }
        }

        .controls {
            margin-top: 30px;

            @media all and (max-width: $break-medium) {
                text-align: center;
            }

            .carousel-control-prev,
            .carousel-control-next {
                width: auto;
                position: static;
                display: block;
                float: left;

                @media all and (max-width: $break-medium) {
                    float: none;
                    display: inline-block;
                }

                i {
                    font-size: 30px;
                }
            }

            .carousel-indicators {
                padding-top: 12px;
                display: block;
                position: static;
                float: left;

                @media all and (max-width: $break-medium) {
                    padding: 0;
                    float: none;
                    display: inline-block;
                    vertical-align: middle;
                }

                li {
                    width: 6px;
                    height: 6px;
                    margin-left: 10px;
                    float: left;
                    @include border-radius(3px);

                    &:first-of-type {
                        margin-left: 0;
                    }

                    &.active {
                        background: $orange;
                    }
                }
            }
        }
    }
}
